import React, { useState, useRef } from "react";
import Lottie from "lottie-react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby";
import { getSourceSet } from '../utils';
import { graphql } from 'gatsby'
import { Typography } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import Etimology from '../Etimology.json';
import Catalina from '../Catalina.json';
import {Grid, Container, TextField, Fab} from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FAQSection from "../FAQSection";
import aboutBanner from '../images/aboutusvid.mp4';
import aboutplaceholder from '../images/aboutus.webp';
import humantouch from '../images/humantouch.mp4';
import humantouchplaceholder from '../images/humantouchplaceholder.webp';
import humantouchsmall from '../images/humantouchsmall.mp4';
import tinyhumantouch from '../images/tinyhumantouch.mp4';
import mission from '../images/mission.mp4';
import origins from '../images/origins.mp4';
import originplacehoder from '../images/origin.webp';
import { playOnIntersect } from "../utils";

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// const subHeading = {
//   lineHeight: '36px',
//   fontSize: '1.5em'
// }

// const subContent = {
//   fontFamily: 'Poppins',
//   fontWeight: 'medium',
//   lineHeight: '30px'
// }

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]

const AboutPage = (props) => {
    //TODO UPDATE THE META DATA PLZ
  const data = props.data;
  const structuredData = `
  {
    "@context": "http://schema.org",
    "@type": "SportsActivityLocation",
    "@id": "${data.site.siteMetadata.siteUrl}",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Mclean",
      "addressRegion": "VA",
      "postalCode":"22102",
      "streetAddress": "8300 Greensboro Drive "
    },
    "name": "Metalinguist",
    "telephone": "+15714820147",
    "openingHoursSpecification": 
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "08:00",
        "closes": "20:00"
      }
    ,
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "38.7716198",
      "longitude": "-77.1098972"
    },
    "url": "https://metalinguist.com",
    "sameAs" : ["https://twitter.com/metalinguistllc"]
  }`;

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
//   const playOnIntersect2 = (entries) => {
//     console.log(entries);
//   }
  const observedRef = useRef(null);
//   const observer = new IntersectionObserver(playOnIntersect2, {
//     threshold: .5 // percentage of target's visible area. Triggers "onIntersection"
//     })
 
//   React.useEffect(() => {
//     debugger;
//     if (observedRef.current){
//        observer.observe(observedRef.current)
//     }
//     return () => {
//         if (observedRef.current) {
//           observer.unobserve(observedRef.current);
//         }
//       };
//   }, [])
const subHeading = {
    lineHeight: xsScreen ? '25px' : '36px',
    fontSize: '1.75em',
    fontWeight: 'medium'
  }


  const subContent = {
    fontFamily: 'Poppins',
    fontSize: '1.5em',
    fontWeight: 'medium',
    lineHeight: xsScreen ? '25px' : '35px',

  }

  return (<Layout currentPage="about" overridePadding={mdScreen ? true: false}>
    <SEO title={'About'} 
      structuredData={structuredData}/>
      <Grid container column spacing={smScreen ? 1 : 8} style={{paddingTop: '5em', fontSize: xsScreen ? '10px' : smScreen ? '10px' : mdScreen ? '12px' : '16px'}}>
        <div style={{position: 'absolute', height: '45em', width: '100%', overflow: 'hidden', top: '0', left: '0', zIndex: 0}}>
          <div  style={{position: 'absolute', height: '100%', width: '100%', display: 'grid'}}>
          <Typography variant="h2" style={{placeSelf: 'center', zIndex: 2, position: 'absolute', fontSize: xsScreen ? '2.5em' : '3em', textAlign: 'center'}}>Creating the bridge between linguists and technology</Typography>
            <video id="about-banner" src={aboutBanner} poster={aboutplaceholder} type="video/mp4" loop autoPlay muted playsInline preload="auto" style={{height: '100%', width: '100%', objectFit: 'cover'}}>
              
            </video>
          </div>
        </div>
        {/* <Grid container item row justifyContent="center" style={{fontSize: xsScreen ? '10px' : smScreen ? '10px' : mdScreen ? '12px' : '16px', textAlign: 'center', marginTop: '15em'}}>
            <Grid item xs={12}></Grid>
        </Grid> */}
        <Grid container item row justifyContent="center" style={{position: 'relative',  marginTop: '37em', padding: xsScreen ? '0' : '5em'}}>
          <Grid item xs={12} style={{textAlign: 'center', zIndex: 2, padding: '2.5em', paddingTop: '4em', paddingBottom: '4em', backgroundColor: 'rgb(87 88 94 / 21%)', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)'}}>
            <Typography variant="h4" style={{fontWeight: 500, fontSize: '2.5em', fontFamily: 'Poppins'}}>About Metalinguist</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', paddingLeft: xsScreen ? '0' : '4em', paddingRight: xsScreen ? '0' : '4em', fontSize: '1.5em', ...subContent}}>
            At Metalinguist, we understand the evolution and transformation that the translation and localization industries are undergoing. With rapid technological advancements, especially in the realm of Generative AI, we’ve stepped forward to fill a significant gap - to provide intuitive tools and platforms that ensure linguists and translators are not just kept in the loop, but are placed at the forefront of this revolution, without feeling left behind.
            <br></br><br></br>Our goal is to always make our software as easy to navigate as this website.
            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
          
        </Grid>
        <div ref={observedRef} style={{position: 'relative', height: '45em', width: '100%', marginTop: '4.5em'}}>
          <div  style={{position: 'absolute', height: '100%', width: '105%', left: '-1em' }}>
            <video id="humantouch" poster={humantouchplaceholder} loop autoPlay muted playsInline style={{height: '100%', width: '100%', objectFit: 'cover'}}>
                {xsScreen ? <source src={humantouchsmall} type="video/mp4" />
                : <source src={humantouch} type="video/mp4" />}
                {/* <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/ogg" /> */}
                Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <Grid container item row justifyContent="center" style={{position: 'relative', padding: xsScreen ? '0' :'5em', marginTop: '4.5em'}}>
          <Grid item xs={12} style={{textAlign: 'center', zIndex: 2, padding: '2.5em', paddingTop: '4em', paddingBottom: '4em', backgroundColor: 'rgb(87 88 94 / 21%)', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)'}}>
            <Typography variant="h4" style={{fontWeight: 500, fontSize: '2.5em', fontFamily: 'Poppins'}}>Made for Humans in a World of Machines</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', paddingLeft: xsScreen ? '0' : '4em', paddingRight:  xsScreen ? '0' : '4em', fontSize: '1.5em', ...subContent}}>
            In today’s rapidly digitizing world, the competition isn’t just with fellow linguists, but with machines and algorithms. Yet, there’s an irreplaceable depth, nuance, and cultural empathy that only a human linguist can offer. As businesses globally become increasingly automated, the value of genuine human interaction in translation surges. 
            <br></br><br></br>Metalinguist isn’t just another tool – it’s your partner in championing that human element. We empower translators to efficiently harness technology, ensuring that they remain indispensable, fostering and deepening connections in an age where the human touch is becoming a premium service.

            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
        </Grid>
        <div style={{position: 'relative', height: '40em', width: '100%', marginTop: '4.5em'}}>
          <div  style={{position: 'absolute', height: '100%', width: '105%', left: '-1em' }}>
            <video id="mission-vid" loop autoPlay muted playsInline style={{height: '100%', width: '100%', objectFit: 'cover'}}>
                <source src={mission} type="video/mp4" />
                {/* <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/ogg" /> */}
                Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <Grid container item row justifyContent="center" style={{position: 'relative', marginTop: '10em', padding: xsScreen ? '0' :'5em'}}>
        {/* <Grid  style={{position: 'absolute', zIndex: 0, backgroundImage: `url(${mission})`, height: '100%'}}></Grid> */}
          <Grid item sm={6} style={{display: 'flex', zIndex: 1, justifyContent: 'center', position: xsScreen ? 'absolute' : 'relative', height: 'fit-content', width: xsScreen && '100%' }}>
            <StaticImage src="../images/missionstatement.png"
              quality={95}
              placeholder={'TRACED_SVG'}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Metalinguist"
              fadeIn={true} 
              loading="eager"
              objectFit="cover"
              style={{position: 'absolute', left: '-6em', top: xsScreen ? '-5em' : smScreen ? '-2em' :'-5em', width: '130%'}}
            />
          </Grid>
          <Grid item sm={6} style={{textAlign: 'left', zIndex: 2, padding: '2.5em', order: xsScreen ? 1 : 2}}>
            <Typography variant="h4" style={{fontWeight: 500, fontSize: '2.5em', fontFamily: 'Poppins'}}>Mission Statement</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontSize: '1.5em', fontFamily: 'Poppins'}}>
            To serve as the essential bridge between translation companies and their clients, dedicated to creating user-friendly, comprehensive tools. Our focus is making advanced translation technology accessible and effortless for all.
            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
        </Grid>
        <Grid container item row justifyContent="center" style={{position: 'relative', padding: xsScreen ? '0' :'5em'}}>
          {/* <Grid  style={{position: 'absolute', zIndex: 0, backdropFilter: 'blur(7px)', height: '100%', width: '200%', backgroundColor: 'rgba(240, 248, 255, 0.25)'}}></Grid> */}
          <Grid item sm={6} style={{textAlign: 'left', padding: '2.5em', zIndex: 1, marginTop: '5em'}}>
            <Typography variant="h4" style={{fontWeight: 500, fontFamily: 'Poppins', fontSize: '2.5em'}}>
            Vision Statement
            </Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontSize: '1.5em', fontFamily: 'Poppins'}}>
            Metalinguist aspires to stand as a beacon of efficiency and user-friendliness for translators globally, and be the software everyone thinks about as being the “modern” software of the language industry. In the midst of the technological shifts driven by GenAI, we’re committed to ensuring every linguist can confidently navigate and thrive.
            </Typography>
          </Grid>
          <Grid item sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
          <StaticImage src="../images/visionstatement.png"
              quality={95}
              placeholder={'TRACED_SVG'}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Metalinguist"
              fadeIn={true} 
              loading="eager"
              objectFit="contain"
            />
          </Grid>
        </Grid>
        <div style={{position: 'relative', height: '40em', width: '100%', marginTop: '4.5em'}}>
          <div  style={{position: 'absolute', height: '100%', width: '105%', left: '-1em' }}>
            <video id="origins-vid" poster={originplacehoder} loop autoPlay muted playsInline style={{height: '100%', width: '100%', objectFit: 'cover'}}>
                <source src={origins} type="video/mp4" />
                {/* <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4" type="video/ogg" /> */}
                Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <Grid container item row justifyContent="center" style={{position: 'relative', padding: xsScreen ? '0' :'5em', marginTop: '4.5em'}}>
          <Grid item xs={12} style={{textAlign: 'center', zIndex: 2, padding: '2.5em', paddingTop: '4em', paddingBottom: '4em', backgroundColor: 'rgb(87 88 94 / 21%)', WebkitBackdropFilter: 'blur(15px)', backdropFilter: 'blur(15px)'}}>
            <Typography variant="h4" style={{fontWeight: 500, fontSize: '2.5em', fontFamily: 'Poppins'}}>A 5000 Foot View  Of Our History</Typography>
            <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', paddingLeft:  xsScreen ? '0' : '4em', paddingRight:  xsScreen ? '0' : '4em', fontFamily: 'Source Sans Pro', fontSize: '1.25em', ...subContent}}>
            In a landscape where language technology was neither user-friendly nor affordable, Metalinguist was born out of necessity. Linguists found themselves at risk of losing clients, bogged down by cumbersome email chains and outdated project management methods. Industry leaders took notice and envisioned Metalinguist initially as a client portal—a way to make project submission as modern and effortless as a drive-thru experience. 
            <br></br><br></br>
            Over time, our vision expanded. What started as a portal to simply streamline project submissions has evolved into an all-encompassing TBMS and translation client solution. Today, Metalinguist is redefining how linguists and their clients interact, making the once cumbersome process not just manageable, but seamless from beginning to end.
            </Typography>
            {/* <Typography variant="body1" style={{lineHeight: 1.75, paddingTop: '25px', fontFamily: 'Source Sans Pro', fontSize: '1.25em'}}>
            Our intuitive user interface allows your clients to painlessly acclimate, onboard and create translation projects within minutes. Say goodbye to email, and hello to a portal that your clients will actually enjoy.
            </Typography> */}
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container justify="center" style={{height: '100%', alignItems: 'center'}}>
            <Box style={{display: 'flex', justifyContent: 'center', width: '600px', backgroundColor: 'transparent', height: 'fit-content', overflowY: 'scroll', position: 'relative'}}>
              <IconButton onClick={() => setOpen(false)} style={{position: 'absolute', top: '1em', right: '3em'}}><CloseIcon /></IconButton>
              <iframe src='https://formcrafts.com/a/qxndssw?iframe=true&resizer=false&innerHeight=1173' width='612' height='1173' style={{maxWidth: '85%', maxHeight: '700px', border: '0'}}></iframe>
            </Box>
          </Grid>
      </Modal>
      </Grid>
   </Layout>
  )
}

export default AboutPage;

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        description
        author
        keywords
        siteUrl
      }
    }
  }
`